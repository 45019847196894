<template>
  <v-dialog v-model="show" fullscreen  hide-overlay transition="dialog-bottom-transition"  id="subscriptionDialog">
    <v-card tile  >
        <v-toolbar color="#00439B" elevation="0" >
          <v-btn icon dark @click="cancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-white" >SELECT USERS</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn :loading="loading.save" dark text @click="submit">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="px-3 border-bottom pb-4 mt-3">
          <v-row dense >
            <v-col cols="12" md="2">
              <small class="text-content mb-0 text-secondary">PRODUCT</small>
              <p class="font-weight-bold mb-0 black--text text-uppercase">{{ arrays.product.name }}</p>
            </v-col>
            <v-col cols="12" md="2">
              <small class="text-content mb-0 text-secondary">BILLING DATE</small>
              <p class="mb-0 black--text ">Every <b> {{ calculate.new_billing_date ? calculate.new_billing_date : calculation.company_billing_date | getDate  }} </b> of the Month</p>
            </v-col>
            <v-col cols="12" md="2">
              <small class="text-content mb-0 text-secondary">SUBSCRIPTION TYPE</small>
              <p class="font-weight-bold mb-0 black--text text-uppercase">{{ status.product_type }}</p>
            </v-col>
            <v-col cols="12" md="2">
              <small class="text-content mb-0 text-secondary">AMOUNT</small>
              <p class="font-weight-bold mb-0 black--text text-uppercase">
              {{ status.product_price | currency(true) }}
              </p>
            </v-col>
            <v-col cols="12" md="4" class="text-right text-secondary">
              <small class="text-content mb-0">TOTAL</small>
              <p class="font-weight-bold mb-0 black--text h5">
                {{ calculation.total | currency(true, true) }}
               </p>
            </v-col>
          </v-row>
        </div>
        
          <div class="d-block mt-3" id="subscriptionsTable">
            <v-data-table

                :headers="header"
                :items="checkFee"
                :loading="loading.table"
                item-key="id"
                show-select
                disable-sort
                v-model="arrays.selected"
                width="100%"
                @toggle-select-all="selectAll">
            <template v-slot:[`item.name`]="{ item }">
              <span > {{ item.user_company.user.first_name}} {{item.user_company.user.last_name}} </span> <span v-if="item.user_company.user.id == arrays.me.id" class="font-weight-bold"> ( You ) </span>
              <sup v-if="item.sub_expired" class="text-danger">Expired</sup>
            </template>
            <template v-slot:[`item.start`]="{ item }">
              <span >  {{ getStart( item ) | displayDatev2 }}   </span>
            </template>
            <template v-slot:[`item.expiration`]="{ item }">
              <span >  {{ getEnd( item ) | displayDatev2 }}    </span>
            </template>
            <template v-slot:[`item.days`]="{ item }"> 
              <span>  {{ getDays(item) }} <span class="grey--text"> day(s) </span>
              </span>
            </template>
            <template v-slot:[`item.amount`]="{ item }">
              <span > {{  getAmount(item) | currency(true) }}  </span>
                <v-tooltip top v-if="getMinimum(item) && item.user_company.user.id != arrays.me.id" color="black">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" x-small icon color="#0A009B" class="border ml-1">
                      <v-icon size="18" class="border-none">mdi-information-variant </v-icon>
                    </v-btn>
                 </template>
                 <div>
                   <small class="d-block mb-0 mt-0 grey--text text-content">Day(s) : <b class="white--text">{{ getDays(item) }}</b> </small>
                   <small class="d-block mb-0 mt-0 grey--text text-content">Min. price : <b class="white--text">{{ arrays.product.minimum_price | currency(true)}} </b> per day  </small>
                   <small class="d-block mb-0 mt-0 grey--text text-content">Total : <b class="white--text">{{  getAmount(item) | currency(true) }} </b> </small>
                 </div>
                </v-tooltip>
              </template>
            </v-data-table>
            <!-- <div class="py-2">
               <Pagination  v-bind:meta="pagination.meta" @page="paginate"> </Pagination>
            </div> -->
           
          </div>
    </v-card>
  </v-dialog>
</template>
<script type="text/javascript">
  // import Pagination from '@/components/Pagination'
  export default {
    components : {
      // Pagination
    },
    props: {
      dialog : Boolean,
      recievedData: Object,
      productData: Object,
      type: String,
      removedUsers: Array,
      requestList: Boolean
    },
    data()
    {
      return {
        loading: {
          table: true,
          save:false
        },
        status : {
          toggle_check_all : true,
          get_all: true,
          product_type : 'Loading...',
          product_data : 'monthly',
          product_price : 0,
          unpaid : false,
          admin_fee : false,
          user_fee : false,
          subscription_end : false,
        },
        calculation: {
          total : 0,
          product_amount : 0,
          product_per_day : 0,
          product_quartely : 0,
          product_semi_annual: 0,
          product_annual : 0,
          new_billing_date : null,
          company_billing_date : null,
          number_of_days : null
        },
        arrays : {
          users_in_company : [],
          users_in_company_all : [],
          product: null,
          selected : [],
          display_item : [],
          all : [],
          me : null,
          adminData : null,
          copy : [],
          admin_subscription : null,
        },
        pagination : {
          meta: null,
          current: null,
        },
        header: [
          {
            text: "Name",
            value: "name",
            class : 'black--text'
          },
          {
            text: "Start of Billing",
            value: "start",
            class : 'black--text'
          },
          {
            text: "Expiration",
            value: "expiration",
            class : 'black--text'
          },
          { 
            text: "# of Days", 
            align: "center", 
            value: "days",
            class : 'black--text'
          },
          {
            text: "Amount", 
            value : 'amount',
            class : 'black--text'
          }
        ]
      }
    },
    computed : {
      show : {
        get() {
          if (this.recievedData) {
            this.populate()
          }
          this.setProductInfo()
          return this.dialog
        },
        set(value){
          this.$emit('input', value)
        } 
      },
      checkFee()
      {
        let data = this.arrays.users_in_company
        if( this.arrays.product && !this.arrays.product.admin_fee && this.arrays.product.user_fee)
        {
          data =  this.arrays.users_in_company.map( x => ({ ...x, isSelectable :  x.user_company.user.id != this.arrays.me.id }) )
          .filter( user => {
            return user
          })
        }

        if(this.arrays.product && this.arrays.product.admin_fee && !this.arrays.product.user_fee)
        {
          
          data =  this.arrays.users_in_company.map( x => ({ ...x, isSelectable :  x.user_company.user.id != this.arrays.me.id }) )
          .filter( user => {
            return user.user_company.user.id == this.arrays.me.id
          }) 
        }


        return data
      },
      autoSelect()
      {
        let data = null
        if( this.arrays.product && !this.arrays.product.admin_fee && this.arrays.product.user_fee)
        {
          data = this.arrays.users_in_company_all.filter( user => {
              return user.user_company.user.id == this.arrays.me.id || this.$options.filters.subscriptionChecker(user.end_of_subscription) 
          })
        }

        if( this.arrays.product && this.arrays.product.admin_fee && this.arrays.product.user_fee)
        {
          data = this.arrays.users_in_company_all.filter( user => {
            return user ||  this.$options.filters.subscriptionChecker(user.end_of_subscription) 
          })
        }

        if(this.arrays.product && this.arrays.product.admin_fee && !this.arrays.product.user_fee )
        {
          data = this.arrays.users_in_company_all.filter(user => {
            return user.user_company.user.id == this.arrays.me.id || this.$options.filters.subscriptionChecker(user.end_of_subscription) 
          })
        }
        return data
      },
    },
    watch: {
      arrays: {
        handler: function()
        {
          this.summary()
        },
        deep : true
      },
      type : function()
      {
          this.setProductInfo()
          this.getTotal()
      }
    },
    created()
    { 
      this.setProductInfo()
    },
    methods : {
      sortParent(item)
      {
        return item.slice().sort(function(a, b) {
          return a.position - b.position
        })
      },
      unpaid()
      {
        this.$router.push('/account/unpaidsubscription')
      },
      getAdmin()
      {

        this.axios.get('/subscriptions?products_id='+this.arrays.product.id+'&subscription_range=true&user_company_id='+this.recievedData.default_user_company.id)
        .then( ({data}) => {
            this.arrays.adminData = data.subscription[0]
        })
        .finally(() => {
            this.getUsersInCompany()
        })
      },
      getPerDay()
      {
        if(this.calculation)
        {
          return this.calculation['product_'+this.status.product_data]
        }else{
          return 0
        }
      },
      getUsersInCompany(page = 1){
        let p =''
        if(page){
          p = page
        }
        this.loading.table = true
        this.loading.save = true
        let company_id = null
        this.arrays.me = this.recievedData
        company_id = this.recievedData.default_user_company.company.id
          this.axios.get('/subscriptions?company_id='+company_id+'&latest_expiration=true&products_id='+this.arrays.product.id+'&page='+p)
          .then(({data}) => {
            this.pagination.meta = data.meta
            this.arrays.users_in_company = this.calculate(data.subscription)
            this.arrays.copy = this.arrays.users_in_company
            data.meta.total_length = this.arrays.users_in_company.length
            if (this.status.get_all) {
              this.getUsersInCompanyAll(company_id)
            }else{
              this.loading.table = false
              this.loading.save = false
            }
            if(page)
            {
              if(window.innerWidth < 600 && document.getElementById('subscriptionsTable') )
              {
                document.getElementById('subscriptionsTable').scrollIntoView({
                   behavior: 'smooth'
                })
              }
            }
          })
      },
      getUsersInCompanyAll(company_id)
      {
        this.axios.get('/subscriptions?&company_id='+company_id+'&latest_expiration=true&products_id='+this.arrays.product.id+'&per_page='+this.pagination.meta.total)
        .then( ({data}) => {
          let all = this.calculate(data.subscription)
          this.arrays.users_in_company = all
          this.arrays.users_in_company_all = all
          this.arrays.selected = this.autoSelect
          this.arrays.all = all
        })
        .finally( () => {
          this.status.get_all = false 
          this.loading.table = false
          this.loading.save = false
          this.summary()
        })
      }, 
      populate()
      {
        if (this.productData.type == 'per user' ) {
           if (this.status.get_all) {
            this.getAdmin()
          }
        }
        
      },
      setProductInfo()
      {
      
      this.arrays.product = this.productData
 
          if (this.type && this.arrays.product) {
            if( this.arrays.product.admin_fee )
            {
              this.status.admin_fee = true
            }else{
              this.status.admin_fee = false
            }

            if(  this.arrays.product.user_fee )
            {
              this.status.user_fee = true
            }else{
              this.status.user_fee = false
            }

            if ( this.type == 'monthly') {
              this.status.product_type = "monthly"
              this.status.product_data = "monthly"
              this.status.product_price = this.arrays.product.monthly_price
              this.calculation.product_amount = this.arrays.product.monthly_price
            }
            if ( this.type == 'quarterly') {
              this.status.product_type = "quarterly"
              this.status.product_data = "quarterly"
              this.status.product_price = this.arrays.product.quarterly_price
              this.calculation.product_amount = this.arrays.product.quarterly_price
            }
            if ( this.type == 'semi_annual') {
              this.status.product_type = "semi annually"
              this.status.product_data = "semi_annual"
              this.status.product_price = this.arrays.product.semi_annual_price
              this.calculation.product_amount = this.arrays.product.semi_annual_price
            }
            if ( this.type == 'annually') {
              this.status.product_type = "annually"
              this.status.product_data = 'annually'
              this.status.product_price = this.arrays.product.annually_price
              this.calculation.product_amount = this.arrays.product.annually_price
            }
          }
      },
      paginate(page)
      {
        this.getUsersInCompany(page)
      },
      cancel()
      {
        this.show = false
      },
      submit()
      {
        this.summary()
        this.includeAdmin() 
        this.$emit('selectedUsers', this.arrays.selected, this.pagination.meta, this.calculation.new_billing_date)
        this.show = false
      },
      selectAll(event)
      {

         let all = this.arrays.users_in_company_all
        if(event.value)
        {
          this.arrays.selected = all
        }else{
          this.arrays.selected = this.autoSelect
     
        }
      },
      getAdminData(data)
      {
        let admin = null
        if(data)
        {
          if(data.user_company.user.id == this.arrays.me.id)
          {
            let exp = this.$options.filters.checkAdminExpired(data.end_of_subscription)
            if(exp)
            {
              data.end_of_subscription = this.$options.filters.today(true)
              this.calculation.company_billing_date = this.$options.filters.today(true)
              this.calculation.new_billing_date = this.$options.filters.today(true)
            }else{
              this.calculation.company_billing_date = this.recievedData.default_user_company.company.billing_date
            }
            if(data.status == 'trial')
            {
              exp = true
            }
            data.superAdmin = true
            data.expired = exp
            admin = data
          }
        }
        return admin
      },
      calculate(users)  
      {
        let adminData = this.getAdminData( this.arrays.adminData )
        
           users.push(adminData)

        for( let i in users)
        {
    
          if ( !isNaN(i)) {


            let sub_m = this.$options.filters.getSubscription( users[i].end_of_subscription, this.calculation.company_billing_date, 'm' , adminData.end_of_subscription, adminData.expired, users[i].expired, users[i].status)
            let sub_q = this.$options.filters.getSubscription( users[i].end_of_subscription, this.calculation.company_billing_date, 'q' , adminData.end_of_subscription, adminData.expired, users[i].expired, users[i].status)
            let sub_sa = this.$options.filters.getSubscription( users[i].end_of_subscription, this.calculation.company_billing_date, 'sa' , adminData.end_of_subscription, adminData.expired, users[i].expired, users[i].status)
            let sub_a = this.$options.filters.getSubscription( users[i].end_of_subscription, this.calculation.company_billing_date, 'a' , adminData.end_of_subscription, adminData.expired, users[i].expired, users[i].status)


            let p_m =  parseFloat(this.productData.monthly_price / 28)
            let p_q =  parseFloat(this.productData.quarterly_price / 89)
            let p_sa = parseFloat(this.productData.semi_annual_price / 181)
            let p_a = parseFloat(this.productData.annually_price / 365)
            
            let monthly = p_m * sub_m.days
            let quarterly = p_q * sub_q.days
            let semi_annual = p_sa * sub_sa.days
            let annually = p_a * sub_a.days

         

            if(sub_m.minimum)
            {
               monthly = parseFloat( sub_m.days * this.productData.minimum_price ) 
            }
            if(sub_q.minimum)
            {
               quarterly = parseFloat( sub_m.days  * this.productData.minimum_price )
            }
            if(sub_sa.minimum)
            {
               semi_annual = parseFloat( sub_m.days * this.productData.minimum_price ) 
            }
            if(sub_a.minimum)
            {
               annually = parseFloat( sub_m.days  * this.productData.minimum_price)
            }

            if(monthly > this.productData.monthly_price)
            {
              monthly = this.productData.monthly_price
            }
            if(quarterly > this.productData.quarterly_price)
            {
              quarterly = this.productData.quarterly_price
            }
            if(semi_annual > this.productData.semi_annual_price)
            {
              semi_annual = this.productData.semi_annual_price
            }
            if(annually > this.productData.annually_price)
            {
              annually = this.productData.annually_price
            }
             


            users[i]['calculate'] = {
              monthly : {
                start: sub_m.start,
                end : sub_m.end,
                days : sub_m.days,
                total_days : sub_m.total_days,
                minimum :  sub_m.minimum,
                amount : this.$options.filters.amount( monthly )
              },
              quarterly : {
                start : sub_q.start,
                end : sub_q.end,
                days : sub_q.days,
                total_days : sub_q.total_days,
                minimum :  sub_q.minimum,
                amount : this.$options.filters.amount( quarterly )
              },
              semi_annual : {
                start : sub_sa.start,
                end : sub_sa.end,
                days : sub_sa.days,
                total_days : sub_sa.total_days,
                minimum : sub_sa.minimum,
                amount : this.$options.filters.amount( semi_annual )
              },
              annually : {
                start : sub_a.start,
                end : sub_a.end,
                days : sub_a.days,
                total_days : sub_a.total_days,
                minimum :  sub_a.minimum,
                amount : this.$options.filters.amount( annually )
              },
            }
          }
        }
        let new_arr = []

            this.getAdminSubscriptionData(users)

            // let include_admin = false

            
            // for(let i in users)
            // {
            //   if(!isNaN(i))
            //   {
            //     if( users[i].user_company_id != this.arrays.me.current_user_company_id)
            //     {
            //       if(!include_admin)
            //       {
            //         include_admin =  this.$options.filters.subscriptionCheckerForAdminAndUser(users[i].end_of_subscription, this.arrays.adminData.end_of_subscription )
            //       }
            //     }
            //   }
            // }

        

            for(let i in users)
            {
              if(!isNaN(i))
              {
                if(users[i].user_company_id == this.arrays.me.current_user_company_id && !this.status.admin_fee && users[i].superAdmin)
                {
           
                  users[i]['key'] = i
                  users[i].calculate.monthly.amount = 0
                  users[i].calculate.quarterly.amount = 0
                  users[i].calculate.semi_annual.amount = 0
                  users[i].calculate.annually.amount = 0

                  // if(include_admin)
                  // {
                  //   users[i].include = true
                  // }else{
                  //   users[i].include = false
                  // }
                  // push to arrange admin always on top
                  new_arr.push(users[i])
                
                }
              }
            }
        
 
        // then push the other users to array
        for(let i in users)
        {
          if(!isNaN(i))
          {
            users[i]['key'] = 'p_'+i
            if(this.$options.filters.subscriptionChecker(users[i].end_of_subscription) )
              {
                users[i].sub_expired = true
              }
            // if(users[i].end_of_subscription <= adminData.end_of_subscription && users[i].user_company_id != this.arrays.me.current_user_company_id && !this.status.admin_fee)
            // {
              users[i].sub_renew = true
            // }

            // if(users[i].user_company_id != this.arrays.me.current_user_company_id && !this.status.admin_fee)
            // {
            //   if(users[i].end_of_subscription < adminData.end_of_subscription || users[i].status == 'trial')
            //   {
            //     new_arr.push(users[i])
            //   }

            // }else{
          
            //   if(users[i].end_of_subscription < adminData.end_of_subscription || users[i].status == 'trial' && users[i].user_company_id != this.arrays.me.current_user_company_id)
            //   {
            //     new_arr.push(users[i])
            //   }
            // }

           
            if( users[i].user_company_id != this.arrays.me.current_user_company_id)
              {
                new_arr.push(users[i])
              }
          }
        }
        return new_arr
      },
      getAdminSubscriptionData(users)
      {
        for(let i in users)
          {
            if(!isNaN(i))
            {
              if(users[i].user_company_id == this.arrays.me.current_user_company_id)
              {
                this.arrays.admin_subscription = users[i]
              }
            }
          }
      },  
      formatNumber(n)
      {
        return Number( n ).toLocaleString()
      },
      includeAdmin()
      {
        let monthly = false
        let quarterly = false
        let semi_annual = false
        let annually = false

  

       if(this.arrays.selected && this.arrays.selected.length > 0) {
         for(let i in this.arrays.selected)
         {
           
           if(!isNaN(i))
           {
             if(this.arrays.selected[i].calculate && this.arrays.selected[i].user_company_id != this.arrays.me.current_user_company_id )
             {
               if(this.$options.filters.subscriptionCheckerForAdminAndUser( this.arrays.selected[i].calculate.monthly.end, this.arrays.admin_subscription.end_of_subscription ) && !monthly )
              {
                monthly = true
      
              }
              if(this.$options.filters.subscriptionCheckerForAdminAndUser( this.arrays.selected[i].calculate.quarterly.end, this.arrays.admin_subscription.end_of_subscription ) && !quarterly )
              {
                quarterly = true
 
              }
              if(this.$options.filters.subscriptionCheckerForAdminAndUser( this.arrays.selected[i].calculate.semi_annual.end, this.arrays.admin_subscription.end_of_subscription ) && !semi_annual )
              {
                semi_annual = true

              }
              if(this.$options.filters.subscriptionCheckerForAdminAndUser( this.arrays.selected[i].calculate.annually.end, this.arrays.admin_subscription.end_of_subscription ) && !annually )
              {
                annually = true
              }
             }
           }
         }
       }
      

       if( this.arrays.selected && this.arrays.selected.length > 0 )
       {
         for(let i in this.arrays.selected)
         {
           if(!isNaN(i) )
           {
             if(this.arrays.selected[i].user_company_id == this.arrays.me.current_user_company_id)
             {
               this.arrays.selected[i].include = {
                 monthly : monthly,
                 quarterly : quarterly,
                 semi_annual : semi_annual,
                 annually : annually
               }
             }
           }
         }
       }
      },
      summary()
      {
        let m = 0
        let q = 0
        let sa = 0
        let a = 0


        if (this.arrays.selected && this.arrays.selected.length > 0) {
   
          for(let i in this.arrays.selected)
          {
            if ( !isNaN(i)) { 


                if(this.arrays.selected[i].calculate)
                {
                  
                  m += parseFloat(this.$options.filters.amount(  this.arrays.selected[i].calculate.monthly.amount)  )
                  q += parseFloat(this.$options.filters.amount(  this.arrays.selected[i].calculate.quarterly.amount)  )
                  sa += parseFloat(this.$options.filters.amount(  this.arrays.selected[i].calculate.semi_annual.amount)  )
                  a += parseFloat(this.$options.filters.amount(  this.arrays.selected[i].calculate.annually.amount)  )
                }
            }
          }
          this.arrays.selected['monthly'] = m 
          this.arrays.selected['quarterly'] = q 
          this.arrays.selected['semi_annual'] = sa 
          this.arrays.selected['annually'] = a 
          this.getTotal()

        }
        else{
          this.calculation.total = 0
          this.getTotal()
        }
      },
      getStart(item)
      {
        return item.calculate[this.status.product_data].start
      },
      getEnd(item)
      {
        return item.calculate[this.status.product_data].end
      },
      getAmount(item)
      {
        return item.calculate[this.status.product_data].amount
      },
      getDays(item)
      {
        return item.calculate[this.status.product_data].days
      },
      getMinimum(item)
      {
        if(item.calculate)
        {
          if(item.calculate[this.status.product_data].minimum)
          {
            return true
          }else{
            return false
          }
        }
      },
      getTotal()
      {
        if(this.arrays.selected)
        {
          this.calculation.total = this.arrays.selected[this.status.product_data]
          if (!this.show) {
            this.response()
          }
        }
       
      },
      response()
      {
        this.$emit('allUsers', this.arrays.all)
        this.$emit('selectedUsers', this.arrays.selected, this.pagination.meta, this.calculation.new_billing_date)
   
      }
    }
  }
</script>
<style type="text/css" scoped>
  .subscriptionDialog{
    scroll-behavior: smooth;
  }
</style>
