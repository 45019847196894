<template>
    <v-stepper-content step="3">
        <v-snackbar v-model="snackbar.show" top right :color="snackbar.color" timeout="2000">
          <v-icon class="mr-2">{{ snackbar.icon }}</v-icon>
          <span> {{ snackbar.text }} </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              color="white"
              icon
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-row class="justify-content-center" v-if="arrays.product">
          <v-col cols="12" md="12">
            <v-row dense class="d-flex align-start flex-wrap">
              <v-col cols="12" sm="12" md="4">
                <v-card outlined 
                 :disabled="status.paypal">
                 <v-overlay :absolute="true" :value="loading.paypal">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
                  <v-card-title> 
                    Paypal
                  </v-card-title>
                  <div>
                    <v-img
                        width="150"
                        height="130"
                        class="mx-auto"
                        src="@/assets/Svg/paypal.svg" >
                    </v-img>
                  </div>
                  <v-card-text >
                    <div class="d-flex align-center flex-wrap" v-if="arrays.wallet">
                      <p class="text-dark w-50 mb-0">
                        <small class="text-content text-secondary text-uppercase d-block">Total Bill</small>
                          <b  class="black--text">{{  computations.payment | currency(true) }} </b>
                          <small class="text-danger" v-if="wallet_input">-{{ wallet_input }}</small>
                      </p>
                      <p class="text-dark w-50 mb-0">
                        <small class="text-content text-secondary text-uppercase d-block">To be paid</small>
                          <b  class="black--text">{{   computations.remaining_payment | currency(true) }} </b>
                      </p>
                    </div>
                    <div v-else>
                       <p class="text-dark mb-0">
                        <small class="text-content text-secondary text-uppercase d-block">total bill</small>
                          <b  class="black--text">{{  computations.payment | currency(true) }} </b>
                      </p>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" sm="12" md="4">
                <v-card outlined  
                :disabled="status.wallet" >
                <v-overlay :absolute="true" :value="loading.wallet">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                  </v-overlay>
                  <v-card-title> 
                    Your Wallet
                  </v-card-title>
                  <v-card-text v-if="arrays.wallet" >
                      <div class="d-flex align-center flex-wrap">
                        <p @click="openInfo" class="blue--text mb-4"> <a> Refer more people and get wallet income that you can user to pay for your bill.  </a></p>
                        <p class="text-dark w-50">
                          <small class="text-content text-secondary text-uppercase d-block">Balance</small>
                            <b  class="black--text">{{  arrays.wallet.balance | currency(true) }} </b>
                            <small class="text-danger" v-if="wallet_input">-{{ wallet_input }}</small>
                        </p>
                        <p class="text-dark  w-50">
                          <small class="text-content text-secondary text-uppercase d-block">remaining balance</small>
                          <b  class="black--text">{{ computations.wallet_remaining_balance | currency(true) }} </b> 
                        </p>
                      </div>
                      <div class="">
                        <p class="mb-0 black--text">You can use your earnings or wallet for payment.</p>
                         <v-text-field 
                         label="Wallet Amount"
                         persistent-hint=""
                         type="number"
                         hide-details=""
                         hint=""
                         min="0"
                         @keyup="computeWallet" 
                         :max="walletMax()" 
                         required 
                         v-model="wallet_input" >
                         </v-text-field>
                      </div>
                  </v-card-text>
                  <div v-else>
                    <v-img
                        width="120"
                        height="130"
                        class="mx-auto"
                        src="@/assets/wallet.svg"
                      >
                    </v-img >
                    <v-card-text>
                      <small class="text-content text-secondary text-uppercase d-block">Wallet Balance </small>
                      <p class="black--text mb-0"> <b> {{ 0 | currency(true) }} </b> </p>
                    </v-card-text>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" lg="8" md="8" >
                  <v-card-text>
                    <p class="subtitle-2 black--text">
                      Proceed to Payment
                    </p>
                    <p class="black--text">
                      Pay via PayPal. Please do not close the browser while the payment process is in progress.
                    </p>
                    <v-card :disabled="status.paypal" flat class="text-left paypalIndex">
                      <div id="paypal-button" ></div>
                    </v-card>
                     <div v-if="!status.submit && wallet_input != computations.payment">
                      <p class="text-center mt-2 text-info text-uppercase rounded mb-0"> Payment Successful </p>
                      <p class="text-center mb-0 black--text font-weight-bold"> Amount Paid : {{   computations.remaining_payment | currency(true) }}  </p>
                    </div>
                    <div v-if="!status.submit && wallet_input == computations.payment">
                      <p class="text-center mt-2 text-info text-uppercase rounded mb-0 "> Using your wallet as payment please proceed to next step</p>
                       <p class="text-center mb-0 black--text font-weight-bold"> Wallet Paid : {{   wallet_input | currency(true) }}  </p>
                    </div>
                  </v-card-text>
              </v-col>
            </v-row>
            <div class="mt-4">
            <v-btn text class="text-secondary" @click="stepBack" :disabled="status.back"> Back </v-btn>
             <v-btn
              color="#0A009B"
              text
              @click.prevent="submit"
              :disabled="status.submit"
              :loading="loading.submit"
            >
              Next
            </v-btn> 
            </div>
            
          </v-col>
          <button type="button" id="triggerPaypalSuccess" class="hidden" @click="setTransactionId" ></button>
          <button type="button" id="paypalOff" class="hidden" @click="paypalOff" ></button>
          <button type="button" id="paypalOff" class="hidden" @click="paypalOn" ></button>
          <input type="text" id="paypalTransactionData" class="hidden" />
          <input type="text" id="payAmount" class="hidden" />
        </v-row>
      </v-stepper-content>
</template>
<script type="text/javascript" >
  export default {
    props : {
      product: Object,
      wallet : Object,
      type : String,
      payment: Number,
      step : Number,
      type_text : String,
      payment_method : Array,
      inviter : Object,
      myInfo : Object,
      selected : Array,
      my_subscription : Array,
      new_company_billing : String
    },  
    data()
    {
      return {
        arrays : {
          me : null,
          product : null,
          wallet : null,
          authorization: null,
          capture_order : null,
          payment_method : null,
          inviter : null,
          selected : null,
          my_subscription : null
        },
        computations : {
          payment : 0,
          remaining_payment : 0,
          wallet_remaining_balance : 0,
          total : 0,
        },
        status : {
          paypal : true,
          wallet : true,
          step : 0,
          submit : true,
          back: false,
          proceed : false,
          referral : false,
          new_company_date : null,
          skip : false,
        },
        loading : {
          paypal : false,
          wallet : false,
          submit : false
        },
        subscription: {
          type : null,
          text : null,
        },
        snackbar: {
          show : false,
          text : 'Transaction success!',
          color : 'success',
          icon : 'mdi-check'
        },
        paypal : {
          rendered : false,
          client_id : 'AYsh9XF0ticW7hN05usPppSinShrSq6WQ0ioiozgNneIrkWj18ogXXTc37Ibst4i2nhVzvbW3vYNCYvT',
        },
        calculation: {
          inviter: null
        },
        wallet_input : null,
        paypal_transaction_id : null,
        paypal_rendered : false,
      }
    },
    watch: {
      product: function(val)
      {
        this.arrays.product = val
      },
      wallet: function(val)
      {
        if(val)
        {
          if(val.balance)
          {
            this.arrays.wallet = val
            this.computations.wallet_remaining_balance = parseFloat( val.balance ) 
            if (val.balance > 0 ) {
              this.status.wallet = false
            }
          }else{
            this.status.wallet = false
          }
        }
      },
      type : function(val)
      {
        this.subscription.type = val
        this.subscription.text = this.type_text
        
      },
      payment : function(val){
   
        if(val)
        {
          val = parseFloat(val)
          this.computations.total = val
          this.computations.payment = val
          this.computations.remaining_payment = val 
          if(document.getElementById('payAmount') )
          {
            document.getElementById('payAmount').value = val
          }
        }
      },
      step: function(val){
        
        this.status.step = val
        if(val == 3)
        {
          this.checker()
        }
        if(!this.paypal.rendered)
        {
          this.paypalbtn()
          
        }else{
          if(this.arrays.wallet)
          {
            this.computeWallet()
          }
        }
        if(this.myInfo)
        {
          this.arrays.me = this.myInfo
        }
      },
      payment_method(val)
      {
        this.arrays.payment_method = val
      },
      inviter(val)
      {
        if(val)
        {
          this.arrays.inviter = val
          this.status.referral = true
        }
      },
      myInfo(val)
      {
        this.arrays.me = val
      },
      selected(val)
      {
        if(val)
        {
          this.arrays.selected = val
        }
      },
      my_subscription(val)
      {
        if(val)
        {
          this.arrays.my_subscription = val
        }
      },
      new_company_billing(val)
      {
        if(val)
        {
          this.status.new_company_date = val
        }
      },
    },
    methods : {
      openInfo()
      {
        let routeData = this.$router.resolve({name: 'YourInfo'});
        window.open(routeData.href, '_blank');
      },
      checker()
      {
        var intervalPaypalChecker = setInterval( checker(), 1000 )
        let rendered = false
        function checker()
        {
          if(document.getElementsByClassName('paypal-button-text') )
          {
            rendered = true
            clearInterval(intervalPaypalChecker)
          }
        }
        if(rendered)
        {
          this.status.paypal = false
        }
      },
      paypalbtn() {
        this.paypalOff() 
          const checkout = this.$config.api + 'checkout-paypal'
          const execute = this.$config.api + 'execute-paypal'
          const environment = this.$config.paypal.production ? 'production' : 'sandbox'
          const client_id = this.$config.paypal.client_id
          const return_url = this.$config.home
        window.paypal.Button
        .render({
          env: environment,
          client: {
            sandbox: client_id,
            production: client_id
          },
          locale: 'en_US',
          style: {
            size: 'responsive',
            color: 'gold',
            shape: 'pill',
            label : 'paypal',
            tagline : 'false'
          },
          experience: {
            input_fields: {
              no_shipping: 1
            }
          },
          commit: true, // Optional: show a 'Pay Now' button in the checkout flow
       
          payment: function(actions) {
            let returnUrl = return_url;
            let amount = document.getElementById('payAmount').value;
            return actions.request.post(checkout, { return_url: returnUrl, amount: amount } )
            .then(res => 
              { 
                return res.id; 
              }
            );
          },
          onAuthorize: function(data, actions) {
            return actions.request.post(execute, {
              payment_id: data.paymentID,
              payer_id: data.payerID
            }).then(function(data) {
              document.getElementById('paypalTransactionData').value =  data.details.transactions[0].related_resources[0].sale.id
              document.getElementById('triggerPaypalSuccess').click();
            });
          },
        }, '#paypal-button')
        this.paypal.rendered = true
        this.paypalOn();
      },  
      checkAmount()
      {
        this.status.submit = true
      },
      walletMax()
      {
        if(this.arrays.wallet)
        {
          if( this.arrays.wallet.balance > this.computations.payment)
          {
            return this.computations.payment
          }else{
            return this.arrays.wallet.balance
          }
        }
      },
      stepBack()
      {
         this.$emit('stepBack')
      },
      computeWallet()
      {
        
        let balance = this.arrays.wallet.balance
        let payment = this.computations.payment
        let max = this.walletMax() 
        let remaining_payment = 0
        let remaining_balance = 0
        let value = this.wallet_input
        if(value > max)
        {
          this.wallet_input = max
          value = max
        }
        if (value > payment) {
          remaining_payment = 0
          value = payment
        }
          remaining_balance = balance - value
          remaining_payment = payment - value

          if(remaining_balance <= 0)
          {
            remaining_balance = 0
          }
          remaining_payment = parseFloat( remaining_payment  )
          remaining_balance = parseFloat( remaining_balance  )
          value = parseFloat(this.$options.filters.currency(value) )


          this.computations.remaining_payment = remaining_payment
          this.computations.wallet_remaining_balance = remaining_balance
          this.wallet_input = value
          document.getElementById('payAmount').value = remaining_payment

        this.checkStatus()
        
      },
      formatNumber(n)
      {
        return Number( n ).toLocaleString()
      },
      paypalOff()
      {
        this.status.paypal = true
        this.loading.paypal = true
      },
      paypalOn()
      {
        this.status.paypal = false
        this.loading.paypal = false
        this.paypal.rendered = true 
      },
      setTransactionId()
      {
        let transaction_id = document.getElementById('paypalTransactionData').value;
        this.paypal_transaction_id = transaction_id
        this.status.paypal = true
        this.status.wallet = true
        this.loading.paypal = false
        this.loading.wallet = false
        this.snackbar.show = true
        this.status.back = true

        this.accounting()
      },
      checkStatus()
      {
        if (this.computations.remaining_payment <= 0) {
          this.status.paypal = true
          this.status.submit = false
        }else{
          this.status.paypal = false
          this.status.submit = true
        }
        if (this.arrays.wallet.amount > 0) {
          this.status.wallet = false
        }
      },
      submit()
      {
        if(this.status.skip)
        {
          this.$emit('goToStep3' )
        }else{
          this.accounting(true)
        } 
        

      },
      // Store Data
      accounting(skip)
      {
        let wallet = null
        let paypal = null
        if ( this.arrays.wallet) {
           wallet = {
            remaining :  this.computations.wallet_remaining_balance,
            payed : this.wallet_input
          }
        }
        if (this.paypal_transaction_id) {
           paypal = {
            transaction_id: this.paypal_transaction_id,
            payed : this.computations.remaining_payment
          }
        }
        this.loading.submit = true
        this.status.submit = false
        let payment_method_paypal_id = null
        let payment_method_wallet_id = null

        let paypal_transaction_id = null
        let paypal_payed = 0
        let wallet_payed = 0

        if (wallet) {
          payment_method_wallet_id = this.checkPaymentMethod('wallet')
          wallet_payed = wallet.payed
        }

        if (paypal) {
          payment_method_paypal_id = this.checkPaymentMethod('paypal')
          paypal_payed = paypal.payed
          paypal_transaction_id = paypal.transaction_id
        }

        this.calculateReferral()


        let data = {
          payment_method_id: payment_method_paypal_id,
          payment_method_wallet : payment_method_wallet_id,
          wallet_amount : wallet_payed,
          product_id : this.arrays.product.id,
          user_id : this.arrays.me.id,
          transaction_date: this.$options.filters.apiDate( ),
          payment_amount: paypal_payed,
          total_amount: this.computations.total,
          type: 1,
          paypal_transaction_id : paypal_transaction_id,
          inviter: this.calculation.inviter,
          hasSubscription : this.arrangeUser(),
        };

        this.axios
        .post("accounting", data)
        .then( () =>{
          if(!this.status.new_company_date)
          {
            this.loading.submit = false
            this.status.skip = true

            if(skip)
            {
              this.$emit('goToStep3' )
            }
          }else{
            this.updateCompanyBilling(skip)
          }
        })
        .catch( () => {
          console.log('accounting post error!')
        });
      },
      checkPaymentMethod(method)
      {
        let m = null

        for(let i in this.arrays.payment_method)
        {
          if (this.arrays.payment_method[i].name == method) {
            m = this.arrays.payment_method[i].id
          }
        }
        if(m)
        {
          return m
        }else{
          return 1
        }
      },
      calculateReferral()
      {
        if(this.arrays.inviter && this.arrays.inviter.referror)
        {
          let referral_status = this.$options.filters.checkInviterExpirationDate(this.arrays.inviter.date_time_created)
          if(!referral_status)
            {
              let product_refferal_type = this.arrays.product.referral_type
              let product_referral_amount = this.arrays.product.referral_amount
              let total = 0
              if(product_refferal_type == 0)
              {
                let percentage = product_referral_amount / 100
                total = this.computations.total * percentage
              }
              if(product_refferal_type == 1)
              {
                total = product_referral_amount
              }
            
              this.calculation.inviter = {
                user_id : this.arrays.inviter.referror.id,
                amount : this.$options.filters.amount( total ),
                invited_company : this.arrays.inviter.company.name
              }
            
            }
        }
      },
      arrangeUser() 
      {
        let arrange = []
        let subscription_type = this.subscription.type
        let company_id = this.arrays.me.default_user_company.company.id
        
        if (this.arrays.product.type == 'per user') {
          


  
          for( let u in this.arrays.selected)
          {
            if (!isNaN(u)) {
              if(this.arrays.selected[u].calculate)
              {
                arrange[u] = {
                  id : this.arrays.selected[u].id,
                  subs_plan_id : null,
                  products_id : this.arrays.product.id,
                  start_of_subscription : this.$options.filters.apiDate( this.arrays.selected[u].calculate[subscription_type].start ) ,
                  end_of_subscription : this.$options.filters.apiDate(  this.arrays.selected[u].calculate[subscription_type].end ),
                  user_company_id : this.arrays.selected[u].user_company_id,
                  subscription_amount : parseFloat( this.$options.filters.amount(  this.arrays.selected[u].calculate[subscription_type].amount) ),
                  transaction_id : null,
                  status : 'paid',
                  expired : this.arrays.selected[u].expired ? true : false,
                  include : this.arrays.selected[u].include ? this.arrays.selected[u].include : false,
                }
              }
            }
          }

          if(arrange.length > 0)
          {
            for(let i in arrange)
            {
              if(arrange[i].user_company_id == this.arrays.me.current_user_company_id)
              {
      
                // if(!arrange[i].expired)
                // {
                //   arrange.splice(i, 1)
                // }
                if(arrange[i].include)
                {
                  if(!arrange[i].include[this.subscription.type])
                    arrange.splice(i, 1)
                  }
              }
            }
          }
          return arrange
        }else{
          let data = [ 
            {
              id : this.arrays.my_subscription.id,
              subs_plan_id : null,
              products_id : this.arrays.product.id,
              start_of_subscription : this.$options.filters.apiDate(  this.arrays.my_subscription.start ),
              end_of_subscription : this.$options.filters.apiDate( this.arrays.my_subscription[this.subscription.type] ),
              user_company_id : company_id,
              subscription_amount : parseFloat( this.$options.filters.amount(  this.computations.total) ),
              transaction_id : null,
              status : 'paid'
            }
          ]
          return data
        }
      },
      updateCompanyBilling(skip)
      {
        let company = this.arrays.me.default_user_company.company
        if(company)
        {
          company.billing_date = this.status.new_company_date
        }
        this.axios.put('companies/' + company.id, company)
        .finally( () => {
          this.loading.submit = false
          this.status.skip = true
          if(skip)
          {
            this.$emit('goToStep3' )
          }
        })
      },

    }
  }
</script>
<style type="text/css">
  .theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
    color: #ccc!important;
    border: 1px solid #ccc!important;
  }
  .hidden{
    display: none;
  }
  .paypalIndex{
    position: relative;
    z-index: 1;
  }
</style>