<template>
  <div>
    <v-stepper v-model="step" vertical class="shadow-lg" id="scrollStepper">
      <v-stepper-step :complete="step > 1" step="1" color="#00919B">
        Subscription Plans
      </v-stepper-step>
      <Step1 :plan="plan" @step2="doneStep1"> </Step1>
      <v-stepper-step :complete="step > 2" step="2" color="#00919B">
        Subscription Type
      </v-stepper-step>
      <Step2
        :plan="plan"
        :step="step"
        :selected_plan="arrays.selected_plan"
        @stepBack="stepBack"
        @step3="doneStep2"
      >
      </Step2>
      <v-stepper-step :complete="step > 3" step="3" color="#00919B">
        Payment
      </v-stepper-step>
      <Step3
        :wallet="wallet"
        :product="product"
        :step="step"
        :payment_method="payment_method"
        :my_info="my_info"
        :inviter="inviter"
        :common="arrays"
         @stepBack="stepBack"
      > </Step3>
    </v-stepper>
  </div>
</template>
<script>
import Step1 from "@/components/SubscribeProduct/SubsPlan/step_1";
import Step2 from "@/components/SubscribeProduct/SubsPlan/step_2";
import Step3 from "@/components/SubscribeProduct/SubsPlan/step_3";
export default {
  props: {
    plan: {
      type: Array,
      // eslint-disable-next-line vue/require-valid-default-prop
      default() {
        return [];
      },
    },
    product: {
      type: Object,
      default() {
        return {};
      },
    },
    wallet : {
        type : Object,
        default() {
            return {};
        },
    },
    payment_method : {
        type : Array,
        default() {
            return [];
        },
    },
    my_info : {
        type : Object,
        default() {
            return {};
        },
    },
    inviter : {
        type : Object,
        default() {
            return {};
        },
    }
  },
  components: {
    Step1,
    Step2,
    Step3,
  },
  data() {
    return {
      step: 1,
      arrays: {
        selected_plan: {},
        subscription_plan: {},
      },
    };
  },
  methods: {
    doneStep1(plan) {
      this.arrays.selected_plan = plan;
      this.step = 2;
    },
    doneStep2(subscription) {
      this.arrays.subscription_plan = subscription;
      this.step = 3;
    },
    stepBack() {
      if (this.step > 1) {
        this.step--;
      }
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
  },
};
</script>
<style>
    .hover-card{
        transition: .3s;
        box-shadow:  none;
    }
    .hover-card:hover{
        box-shadow: 0 0 20px rgb(192, 192, 192)!important;
    }
      #selected {
       box-shadow: 0 0 20px rgb(192, 192, 192)!important;
    }
</style>