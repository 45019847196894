<template>
    <v-stepper-content step="2" class="pl-4">
  
            <v-row class="" v-if="subscriptionTypes.length > 0"  >
                <v-col cols="12" lg="3" md="6" sm="12" v-for="(item,index) in subscriptionTypes" :key="index" >
                    <v-card class="text-center  py-6 shadow-none ">
                            <h3 class="text-capitalize font-weight-bold"> {{ item.amount | currency(true) }}  </h3>
                             <v-btn  min-width="150" v-if="subscription && subscription.periodical == item.periodical"  
                                color="#00919B" 
                                @click="subscription = item" 
                                class="mt-4 shadow text-white">
                                {{ item.periodical }}
                            </v-btn>
                            <v-btn v-else min-width="150" color="#0A009B" @click="subscription = item"  outlined class="mt-4" >
                                {{ item.periodical }}
                            </v-btn>
                    </v-card>
                </v-col>
            </v-row>

        <v-btn text small class="text-secondary" @click="cancel">
            Cancel
        </v-btn>
        <v-btn
        color="#0A009B"
        text
        @click="next"
        :disabled="!subscription"
        >
        next
        </v-btn>
        
    </v-stepper-content>
</template>
<script>
export default {
    props : {
        plan : {
            type : Array,
            default() {
                return []
            }
        },
        selected_plan : {
            type : Object,
            default() {
                return {}
            }
        },
        step : {
            type : Number,
            default : 0
        }
    },
    watch : {
        step : function(step)
        {
            if(step == 2)
            {
                this.triggerStep2()
            }
        }
    },
    data()
    {
        return {
            subscription : null,
            status : {
                free : false,
            }
        }
    },
    computed : {
        subscriptionTypes()
        {
            var types = []
            if(this.plan.length > 0 && this.selected_plan)
            {
                for(var i in this.plan)
                {
                    if(!isNaN(i))
                    {
                        if(this.plan[i].plan == this.selected_plan.plan)
                        {
                            types.push(this.plan[i])
                        }
                    }
                }
            }
            return types
        }
    },
    methods : {
        triggerStep2()
        {
            if(this.selected_plan.plan.toLowerCase() == 'free' )
            {
                this.status.free = true
            }
        },
        cancel()
        {
            this.subscription = null
            this.$emit('stepBack', true)
        },
        next()
        {
            this.$emit('step3', this.subscription)
        }
    }
}
</script>