<template>
 <v-stepper-content step="1" color="primary">
     <div v-if="arrays.product">
     <v-row class="scrollablesmall mb-1 mt-2 p-0 m-0"  >
      <v-col cols="12" lg="3" md="6" sm="12" class="mb-1 p-0" v-if="arrays.product.monthly_price > 0" >
        <small class="text-caption text-secondary d-block text-uppercase mb-2">Monthly</small>
        <v-btn
            :value="arrays.product.monthly_price"
            text
            :loading="loading.buttons"
            block
            :color="display.type == 'monthly' ? '#0A009B' : 'grey' "
            aria-pressed="true"
            @click="subscriptionType('monthly', 'Month')"
            :disabled="disabled.monthly"
            >
            <span>  {{ arrays.product.monthly_price | currency(true) }} </span> / 1 month
          </v-btn>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" class="mb-1 p-0" v-if="arrays.product.quarterly_price > 0">
          <small class="text-caption text-secondary d-block text-uppercase mb-2">Quarterly</small>
          <v-btn
            :value="arrays.product.quarterly_price"
            text
            :loading="loading.buttons"
            :color="display.type == 'quarterly' ? '#0A009B' : 'grey' "
            :disabled="disabled.quarterly"
            block
            
            @click="subscriptionType('quarterly', 'Quarterly')" >
             <span> {{ arrays.product.quarterly_price | currency(true) }} </span>  / 3 months </v-btn>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" class="mb-1 p-0" v-if="arrays.product.semi_annual_price > 0">
         <small class="text-caption text-secondary d-block text-uppercase mb-2">Semi Annual</small>
         <v-btn
            :value="arrays.product.semi_annual_price"
            text
            :loading="loading.buttons"
            :color="display.type == 'semi_annual' ? '#0A009B' : 'grey' "
            :disabled="disabled.semi_annual"
            block
            @click="subscriptionType('semi_annual', 'Semi Annual')" >
          <span > {{ arrays.product.semi_annual_price | currency(true) }}  </span> / 6 months </v-btn>
      </v-col>
      <v-col cols="12" lg="3" md="6" sm="12" class="mb-1 p-0" v-if="arrays.product.annually_price > 0">
         <small class="text-caption text-secondary d-block text-uppercase mb-2">Annually</small>
        <v-btn
            :value="arrays.product.annually_price"
            :disabled="disabled.annually"
            text
            :loading="loading.buttons"
            block
            :color="display.type == 'annually' ? '#0A009B' : 'grey' "
            @click="subscriptionType('annually', 'Annually')" >
           <span > {{ arrays.product.annually_price | currency(true) }} </span>  / 12 months </v-btn>
      </v-col>
    </v-row>
  </div>
  <div v-else class="py-4 text-center">
    <p class="text-content text-uppercase mb-0 text-center"> <small> Fetching Product Details<br>
      please wait... </small> </p>
    <v-progress-circular
      :size="64"
      color="grey"
      indeterminate
      class="text-center mt-2 " >
    </v-progress-circular>
  </div>
 <div class="pt-4">
 <v-btn text small class="text-secondary" @click="cancel">
   Cancel
 </v-btn>
  <v-btn
   color="#0A009B"
   text
   @click="goToStep1"
   :loading="loading.continue"
 >
   next
 </v-btn>
</div>
 </v-stepper-content>
</template>
<script>
export default {
 props : ['product', 'self'],
 data() {
  return {
   arrays : {
    product : null,
   },
   display : {
    type : 'monthly',
    amount : 0,
    text : 'Month'
   },
   loading : {
    continue : true,
    buttons: true,
   },
   status : {
     subscription : false,
     expired : false,
     admin_trial : false

   },
   disabled : {
     monthly : false,
     quarterly : false,
     semi_annual : false,
     annually : false
   }

  }
 },
 watch: {
  product(data)
  {
   if(data){
    this.arrays.product = data
    this.display.amount = this.product.monthly_price
    // this.loading.continue = false
    this.getSubscription()
   }
  }
 },
 created()
 {
  if(this.product)
  {
   this.arrays.product = this.product
   this.display.amount = this.product.monthly_price
  //  this.loading.continue = false
  this.getSubscription()
  }
 },
 methods : {
  getSubscription()
  {
    if(this.self)
    {
      if(!this.status.subscription)
      {
        this.axios.get('/subscriptions?user_company_id='+this.self.current_user_company_id+"&latest_expiration=true&products_id="+this.product.id)
        .then( ({data}) => {
          if(data.subscription && data.subscription.length > 0)
          {
            if(data.subscription[0].end_of_subscription)
            {
     
              if(this.$options.filters.subscriptionChecker(data.subscription[0].end_of_subscription) || data.subscription[0].status == 'trial' )
              {

                this.status.expired = true
                this.loading.buttons = false

                if(data.subscription[0].status == 'trial')
                {
                  this.status.admin_trial = true
                }
              }else{
                this.status.expired = false
                // this.checkSubscriptionType(data.subscription[0])
                this.loading.buttons = false
              }
            }
            
          }else{
              this.$router.push('/account/servicessubscription')
            }
        })
        this.loading.continue = false
        
        this.status.subscription = true
      }
    }
  },
  checkSubscriptionType(data)
  {
    if(data)
    {
      let getDayBetween = this.$options.filters.betweenDates(data.start_of_subscription, data.end_of_subscription)

      for(let i in this.disabled)
      {
        this.disabled[i] = true
      }
      if(getDayBetween <= 31)
      {
        this.subscriptionType('monthly', 'Month')
        this.disabled.monthly = false
      }
      if(getDayBetween > 31 && getDayBetween <= 92)
      {
        this.subscriptionType('quarterly', 'Quarterly')
        this.disabled.monthly = false
        this.disabled.quarterly = false
      }
      if(getDayBetween > 92 && getDayBetween <= 184)
      {
        this.subscriptionType('semi_annual', 'Semi Annual')
        this.disabled.monthly = false
        this.disabled.quarterly = false
        this.disabled.semi_annual = false
      }
      if(getDayBetween > 184)
      {
        this.subscriptionType('annually', 'Annually')
        this.disabled.monthly = false
        this.disabled.quarterly = false
        this.disabled.semi_annual = false
        this.disabled.annually = false
      }
      
    }
  },
  cancel()
  {
    this.$router.push({path: '/account/servicessubscription'})
  },
  goToStep1()
  {
  this.$emit('step1', this.display.type, this.display.text)
  },
  subscriptionType(type, text)
  {
    this.display.type = type
    this.display.amount = this.arrays.product.monthly_price
    this.display.text = text
    this.$emit('SubscriptionType', type, text)
  }
 }
}
</script>