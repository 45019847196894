<template>
   <v-stepper-content step="2" color="primary">
   
        <div v-if="arrays.product">
          <p class="mb-4">
            <span v-if="arrays.product.type == 'per user'"> The product you want to subscribe is per user based payment, please select the users you want pay.</span>
            <span v-else>Bulk products automatically selects all the users under your company.</span>
          </p>
            <v-row v-if="arrays.product.type == 'per user'">
              <v-col cols="12" v-if="arrays.selected">
                <p class="text-content  mb-2">
                <v-row justify="start" align="start" dense>
                  <v-col cols="12" md="3">
                    <small class="text-caption text-uppercase text-secondary">
                      selected Subscription Type
                    </small>
                    <p class="mb-0" >
                      <b class="text-dark text-uppercase" > {{ display.subscription_type == 'semi_annual' ? 'Semi Annually' : display.subscription_type  }} </b> 
                    </p>
                  </v-col>
                  <v-col cols="12" md="2">
                    <small class="text-caption text-uppercase text-secondary"> Expired Users </small>
                    <p class="mb-0" >
                      <b class="text-dark" v-if="display.expired"> {{ display.expired.length }} </b> 
                      <b class="text-content" v-else>0</b>
                    </p>
                  </v-col>
                  <v-col cols="12" md="2">
                    <small class="text-caption text-uppercase text-secondary"> For Renewal</small>
                    <p class="mb-0" >
                      <b class="text-dark" v-if="display.renewal && display.renewal.length > 0"> {{ display.renewal.length }} </b> 
                      <b class="text-content" v-else>0</b>
                    </p> 
                  </v-col>
                   <v-col cols="12" md="2">
                    <small class="text-caption text-uppercase text-secondary"> Selected users</small>
                    <p class="mb-0" >
                      <b class="text-dark" v-if="display.selected_users && display.selected_users > 0"> {{ display.selected_users }} </b> 
                      <b class="text-content" v-else>0</b>
                    </p> 
                  </v-col>
                  <v-col  cols="12" md="3" >
                      <small class="text-caption text-uppercase text-secondary"> total payment </small>
                    <p class="mb-0">
                      <b class="text-dark" v-if="display.total_payment"> {{ display.total_payment | currency(true) }} </b>
                      <b class="text-content" v-else>0</b>
                    </p>
                  </v-col>
                  <v-col cols="12 mt-2">
                    <div class="border rounded-lg px-4 py-2 mb-4">
                      
                        <span > This company </span>
                          <b v-if="arrays.me" class=""> 
                            {{ arrays.me.default_user_company.company.name }}
                          </b>
                          has 
                          <b v-if="arrays.allUsers">
                            {{ arrays.allUsers.length }} 
                          </b>
                          users 
                        <span class="action--text font-weight-bold hover" @click="openDetail"> view users. </span>
                    </div>
                    <p class="text-caption text-uppercase text-secondary mb-2">
                      User billing details
                    </p>
                    <v-btn
                      color="#0A009B"
                      text
                      block
                      @click="openUsersList"
                      :loading="loading.filter_user"
                    >
                    <v-icon left>
                      mdi-account-group-outline
                    </v-icon>
                    <span>  SELECTED USERS </span>
                         
                    </v-btn>
                  </v-col>
               </v-row>
       
               
              </p>
     
              </v-col>
              <!--End Filter User Table -->
            </v-row>
            <v-card v-else class="mx-1" outlined :disabled="!status.my_subscription">
               <v-row  class="px-2 py-3">
                <v-col cols="12" class="mb-0 py-0">
                  <small class="text-caption text-uppercase text-secondary">
                    Billing date
                  </small>
                  <p class="mb-0" >
                    <span v-if="arrays.me"> Every <b> {{ status.new_company_date ? status.new_company_date : arrays.me.default_user_company.company.billing_date | getDate }}</b> of the month  </span>
                    <span v-else> Loading.. </span>
                  </p>
                </v-col>
                <v-col cols="12" md="3">
                  <small class="text-caption text-uppercase text-secondary">
                    Start of subscription
                  </small>
                <p class="mb-0" >
                  <span v-if="arrays.my_subscription">  {{ status.my_subscription ? arrays.my_subscription.start : arrays.my_subscription[0].start_of_subscription  | displayDatev2 }} </span>
                  <span v-else> Loading.. </span>
                </p>
                </v-col>
                <v-col cols="12" md="3"> 
                  <small class="text-caption text-uppercase text-secondary"> Expiration </small>
                  <p  class="mb-0">
                    <span v-if="arrays.my_subscription">  {{ status.my_subscription ? arrays.my_subscription[display.subscription_type] : arrays.my_subscription[0].end_of_subscription  | displayDatev2 }} </span>
                    <span v-else> Loading.. </span>
                  </p>
              
                </v-col>
                <v-col cols="12" md="3">
                  <small class="text-caption text-uppercase text-secondary"> Subscription type </small>
                  <p  class="mb-0">
                    <span v-if="display.category"> {{ display.category }}</span>
                    <span v-else> Loading.. </span>
                  </p>
                </v-col>
                <v-col cols="12" md="3">
                  <small class="text-caption text-uppercase text-secondary"> Amount </small>
                  <p  class="mb-0 font-weight-bold black--text">
                    <span v-if="display.subscription_amount"> {{ display.subscription_amount | currency(true) }}</span>
                    <span v-else> 0 </span>
                  </p>
                </v-col>
              </v-row>
            </v-card>
           
            <div class="pt-4">
             
              <v-btn text small class="text-secondary" @click="cancel"> Back </v-btn>
               <v-btn
                color="#0A009B"
                text
                @click="goToStep2"
                :loading="loading.continue"
                :disabled="status.continue"

              >
                next
              </v-btn>
            </div>
          </div>
          <div v-else class="py-4 text-center">
            <p class="text-content text-uppercase mb-0 text-center"> <small> Fetching User Details<br>
              please wait... </small> </p>
            <v-progress-circular
              :size="64"
              color="grey"
              indeterminate
              class="text-center mt-2 " >
            </v-progress-circular>
          </div>

 <UserSelect
        v-if="arrays.me && arrays.product"
        v-model="dialogs.users"
        v-bind:dialog="dialogs.users"
        v-bind:recievedData="arrays.me"
        v-bind:productData="arrays.product"
        v-bind:type="display.subscription_type"
        v-bind:removedUsers="arrays.selected"
        @selectedUsers="selectedUsers"
        @allUsers="allUsers"
      ></UserSelect>
  </v-stepper-content>
</template>
<script type="text/javascript">
  import UserSelect from "@/components/SubscribeProduct/select_users";
  export default {
    components : {
      UserSelect
    },
    props : {
      product : Object,
      self : Object,
      step : Number,
      type : String,
      type_text : String,
    },
    data() {
      return {
        arrays : {
          me : null,
          product : null,
          users : null,
          selected : [],
          my_subscription: null,
          allUsers : null
        },
        display: {
          subscription_amount : 0,
          product : false,
          subscription_type : 'monthly',
          subscription_text : 'Month',
          total_users : 0,
          selected_users : 0,
          total_payment : 0,
          category : 'Monthly',
          expired : 0,
          renewal : 0
        },
        loading : {
          filter_user : true,
          continue : true,
        },
        dialogs : {
          users : false,
          product : null,
          request: true,
        },
        status : {
          admin_fee : false,
          user_fee : false,
          fetching_users : true,
          continue : true,
          list : true,
          admin : false,
          new_company_date : null,
          my_subscription : false
        }
      }
    },
    created()
    {
      if(this.self)
      {
        this.arrays.me = this.self
      }
    },
    watch: {
      product : function(val)
      {
        
        this.arrays.product = val
        this.display.subscription_amount = this.arrays.product.monthly_price
        if (val.type != 'per user') {
          this.getMyLatestSubscription()
        }
        this.display.product = true
        if(val.admin_fee)
        {
          this.status.admin_fee = true
        }else{
          this.status.admin_fee = false
        }
        
      },
      self : function(val)
      {
        if(val.default_user_company.role == 'SA')
        {
          this.status.admin = true
        }
        this.arrays.me = val
        this.setProductDisplay()
        this.checkContinue()
        this.getMyLatestSubscription()
      },
      type : function(type)
      {
        if(type)
        {
          this.display.subscription_amount = this.arrays.product[type+'_price']
          this.display.subscription_type = type
          this.display.subscription_text = this.type_text
          this.setProductDisplay()
        }
      }
    },
    computed : {
      expiredUsers()
      {
        let data =  []
        if(this.arrays.allUsers)
        {
          data = this.arrays.allUsers.filter(user => {
            return user.sub_expired
          })
        }
        return data
      },
      renewUsers()
      {
        let data = []
        if(this.arrays.allUsers)
        {
          data = this.arrays.allUsers.filter(user => {
            return user.sub_renew 
          })
        }
        return data
      }
    },
    methods : {
      openDetail()
      {
        let url = this.$router.resolve({path : '/product/' + this.arrays.product.id })
        window.open(url.href, '_blank')
      },
      cancel()
      {
        this.$emit('stepBack')
      },
      allUsers(users)
      {
        this.arrays.allUsers = users
        this.display.renewal = this.renewUsers
        this.display.expired = this.expiredUsers
      },
      getMyLatestSubscription()
      {
        let company_id = null
        
          company_id = this.arrays.me.default_user_company.company.id

        
       
        if(this.arrays.me && this.arrays.product)
        {
          this.axios.get('/subscriptions?user_company_id='+company_id+'&latest_expiration=true&products_id='+this.arrays.product.id)
          .then( ({data}) => {
              this.arrays.my_subscription = data.subscription
              this.getMyBillingDate()
          })
        }
      },
      checkExpired()
      {
        if(this.arrays.me)
        {
          let exp = this.$options.filters.checkAdminExpired( this.arrays.my_subscription[0].end_of_subscription ) 
          if(exp)
          {
            this.arrays.my_subscription[0].end_of_subscription = this.$options.filters.today(true)
            this.status.new_company_date = this.$options.filters.today(true)
          }

        }
      },
      getMyBillingDate()
      {
        let latest_sub = this.arrays.my_subscription
        let billing_date = this.arrays.me.default_user_company.company.billing_date
        let start = null

        if(this.arrays.my_subscription.length > 0)
        {
          start = this.$options.filters.userStartofSubscription( this.arrays.my_subscription[0].end_of_subscription )
        }else{
          start = this.$options.filters.userStartofSubscription( billing_date )
        }
        
        if( this.$options.filters.checkAdminExpired( this.arrays.my_subscription[0].end_of_subscription ) || this.arrays.my_subscription[0].status == 'trial' )
        {
          let end_m = this.$options.filters.billingDate( start, billing_date, 'm' )
          let end_q = this.$options.filters.billingDate( start, billing_date, 'q' )
          let end_sa = this.$options.filters.billingDate( start, billing_date, 'sa' )
          let end_a = this.$options.filters.billingDate( start, billing_date, 'a' )

          this.status.my_subscription = true 
          latest_sub['start'] = this.arrays.my_subscription[0].end_of_subscription
          latest_sub['monthly'] = end_m
          latest_sub['quarterly'] = end_q
          latest_sub['semi_annual'] = end_sa
          latest_sub['annually'] = end_a
          this.arrays.my_subscription = latest_sub
        }
        this.checkContinue()
      },
      setProductDisplay()
      {
        this.display.subscription_amount
        this.display.product = true

        if(this.arrays.product)
        {
          if(this.display.subscription_type == 'monthly')
          {
            this.display.category = 'Monthly'
          }
          if(this.display.subscription_type == 'quarterly')
          {
            this.display.category = 'Quarterly'
          }
          if(this.display.subscription_type == 'semi_annual')
          {
            this.display.category = 'Semi Annually'
          }
          if(this.display.subscription_type == 'annually')
          {
            this.display.category = 'Annually'
          }
        }
      },

      usersListRemove(e)
      {

        let ar = this.arrays.selected.filter( item => {
          return item.id != e
        })
        this.arrays.selected = ar
        let m = 0
        let q = 0
        let sa = 0
        let a = 0

          for(let i in this.arrays.selected)
          {
            if ( !isNaN(i) ) { 
                m += parseFloat(  this.arrays.selected[i].calculate.monthly.amount) 
                q += parseFloat( this.arrays.selected[i].calculate.quarterly.amount) 
                sa += parseFloat(  this.arrays.selected[i].calculate.semi_annual.amount) 
                a += parseFloat(  this.arrays.selected[i].calculate.annually.amount) 
            }
          }
          this.arrays.selected.monthly = m
          this.arrays.selected.quarterly  = q
          this.arrays.selected.semi_annual  = sa
          this.arrays.selected.annually  = a
      },
      goToStep2()
      {
     
        if (this.product.type == 'per user') {
          this.$emit('step2', this.arrays.selected, this.display.subscription_type, parseFloat( this.display.total_payment), this.status.new_company_date  )
        }else{
          this.$emit('step2', this.arrays.my_subscription, this.display.subscription_type, parseFloat( this.display.subscription_amount), this.status.new_company_date   ) 
        }
        
      },
      openUsersList()
      {
        this.dialogs.users = true
      },
      selectedUsers(list, meta, new_company_date)
      {
        if (this.product.type == 'per user') {
          if (list) {
            this.display.selected_users = list.length
            if(!this.status.admin_fee)
            {
              this.display.selected_users--
            }
          }
          if (meta) {
            this.display.total_users = meta.total_length
          }
          if(new_company_date)
          {
           this.status.new_company_date = new_company_date
          }
          this.arrays.selected = list

      
          
          this.loading.continue = false
          this.loading.filter_user = false
          this.getTotalPayment()
          
        }
        this.checkContinue()
        
      },
      getTotalPayment()
      {
        this.display.total_payment = this.arrays.selected[this.display.subscription_type]

      },
      checkContinue()
      { 
        if(this.product)
        {
          // produuct is per user
          
          if (this.product.type=='per user') {
            if (this.arrays.selected.length > 0 && this.arrays.selected && this.display.total_payment && this.display.total_payment > 0) {
              this.status.continue = false
              this.loading.continue = false
              this.loading.filter_user = false
              this.status.list = false
              this.status.fetching_users = true
            }else{
              this.status.continue = true
            }
          }
          // product is per bulk
          if(this.product.type=='bulk')
          {
            if(this.arrays.my_subscription)
            {
              this.status.continue = false
              this.loading.continue = false
              if(!this.status.my_subscription)
              {
                this.status.continue = true
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.mr-md-4 {
  text-transform: none;
  font-size: small;
}
.scrollablesmall {
  overflow-x: auto;
  cursor: pointer;
}
.scrollablesmall::-webkit-scrollbar {
  width: 0;
}
button:active,
button:focus {
  outline: none;
}
.action--text{
  color: #0A009B;
}
.action--text:hover{
  cursor: pointer;
}
</style>