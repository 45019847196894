
<template>

    <v-container >
      <h2>You’re one step away to start using {{ arrays.product ? arrays.product.name : 'Etousoft Product' }} </h2>
      <div v-if="arrays.product" class="mb-4">
              By subscribing, you agree to the
              Store Terms of Sale. Starting today, we'll charge you
              <strong>{{ display.payment | currency(true) }} </strong> per <b> {{ display.text }} </b>. Cancel any time to stop future charges by visiting
              your Etousoft account.
        <v-alert type="info" class="border mt-2" colored-border border="left" icon="mdi-information-outline" v-if="!arrays.product.admin_fee || !arrays.product.user_fee">
            <span v-if="!arrays.product.admin_fee && arrays.product.user_fee" > The pricing for this product includes free usage for the admin. </span>
            <span v-if="arrays.product.admin_fee && !arrays.product.user_fee" > The pricing for this product includes free usage for the users. </span>
            
        </v-alert>
        
      </div>
      <v-row  align="center" dense v-if="!status.subs_plan">
        <v-col cols="12">
          <v-stepper v-model="step" vertical class="shadow-lg" id="scrollStepper">
            <v-stepper-step :complete="step > 1" step="1" color="#00919B" >
               Select your Subscription Type
            </v-stepper-step>
              <step1  v-bind:product="arrays.product" @step1="goToStep1" v-bind:self="arrays.me"   @SubscriptionType="setSubscription"  id="step_1">

              </step1>
            <v-stepper-step :complete="step > 2" step="2" color="#00919B"  >
              Select Users
            </v-stepper-step>
            <step2 
              v-bind:step="step"
              v-bind:type_text="step_1.subscription_text"
              v-bind:type="step_1.subscription_type"
              v-bind:product="arrays.product"
              v-bind:self="arrays.me"
              @step2="goToStep2"
              @stepBack="stepBack"
              id="step_2"
            > </step2>

            <v-stepper-step :complete="step > 3" step="3" color="#00919B">
              Payment and Confirmation
            </v-stepper-step>
            <step3 
              id="step_3"
              v-bind:product="arrays.product"
              v-bind:type_text="step_1.subscription_text"
              v-bind:wallet="arrays.wallet"
              v-bind:type="step_2.subscription_type"
              v-bind:payment="step_2.payment"
              v-bind:step="step"
              v-bind:payment_method="arrays.payment_method"
              v-bind:myInfo="arrays.me"
              v-bind:selected="step_2.selected"
              v-bind:my_subscription="step_2.my_subscription"
              v-bind:new_company_billing="status.new_company_date"
              v-bind:inviter="arrays.inviter"
              @stepBack="stepBack"
              @goToStep3="goToStep3"
            > </step3>
            <v-stepper-step :complete="step > 4" step="4" color="#00919B">
              Finish
            </v-stepper-step>
            <v-stepper-content step="4" v-if="arrays.product"  id="step_4">
              <v-row>
                <v-col cols="12">
                  <h3>Thank you for subscribing to {{ arrays.product.name }}. Your business means a lot to us.</h3>
                </v-col>
              </v-row>
              <v-btn
                color="#0A009B"
                text
                :loading="step_3.return"
                @click="back"
              >
                back to Subscription
              </v-btn>
            </v-stepper-content>
          </v-stepper>
        </v-col>
      </v-row>
      <SubsPlan v-else 
        :plan="arrays.plans"
        :product="arrays.product"
        :wallet="arrays.wallet"
        :payment_method="arrays.payment_method"
        :my_info="arrays.me"
        :inviter="arrays.inviter"
      ></SubsPlan>
     
    </v-container>

</template>

<script>
import step1 from '@/components/SubscribeProduct/step_1'
import step2 from '@/components/SubscribeProduct/step_2'
import step3 from '@/components/SubscribeProduct/step_3'
import SubsPlan from '@/components/SubscribeProduct/SubsPlan/index'
export default {
  components: {
    step1, step2, step3, SubsPlan
  },
  props : {
    myInfo : Object
  },
  data: () => ({
    arrays : {
      product : null,
      me : null,
      selected : null,
      wallet : null,
      payment_method : null,
      inviter : null,
      paypal_authorization: null,
      plans : []
    },
    display: {
      type : 'monthly',
      text : 'Month',
      payment : 0
    },
    step: 1,
    step_1: {
      users : null,
      subscription_type : null,
      subscription_text : 'Month'
    },
    step_2 : {
      selected : null,
      payment : 0,
      subscription_type : null,
      my_subscription : null,
    },
    step_3: {
      return : true,
      wallet : null,
      paypal : null,
      data : null,
      payment_method: null,
    },
    status : {
      admin : false,
      referral : false,
      code : null,
      convertion_amount : null,
      country : null,
      new_company_date : null,
      payment_method : false,
      subs_plan : false,
    },
    calculation: {
      inviter: null
    },
    alert : true
  }),
  watch : {
    wallet_input : function()
    {
      this.step2WalletCalculation()
    },
    myInfo : function()
    {
      this.getUserInfo()
      this.getPaymentMethods()
    }
  },
  created() {
    if(this.myInfo)
    {
      this.getUserInfo();
      this.getPaymentMethods()
    }
    this.$loadScript("https://www.paypalobjects.com/api/checkout.js")
  },
  computed : {
    hasUnpaid() {
      if(this.step_2.selected && this.step_2.selected.length > 0)
      {
         return this.step_2.selected.filter( user => {
          return user.status == 'unpaid'
        });
      }else{
        return []
      }
    }
  },
  methods: {
    setSubscription(type, text)
    {
      this.display.type = type
      this.display.text = text
      this.display.payment = this.arrays.product[type+'_price']
    },
    back()
    {
      this.$router.push( {path : '/account/servicessubscription' })
    },
    getPaymentMethods()
    {
      if(!this.status.payment_method)
      {
        this.status.payment_method = true
        this.axios.get('/paymentmethods')
        .then( ({data}) => {
          for(let i in data.payment_method){
            if(data.payment_method[i].name)
            {
              data.payment_method[i].name = data.payment_method[i].name.toLowerCase()
            }
          }
          this.arrays.payment_method = data.payment_method
        })
      }
      
    },
    goToStep1(type, text)
    {
      this.step_1.subscription_type = type
      this.step_1.subscription_text = text
      this.step = 2
    },
    goToStep2(selected, type, payment, new_company_date)
    {
      if (this.arrays.product.type == 'per user') {
        this.step_2.selected = selected
      }else{
        this.step_2.my_subscription = selected
      }

      if(new_company_date)
      {
        this.status.new_company_date = new_company_date
      }
      this.step_2.payment = parseFloat(  payment  )
      this.step_2.subscription_type = type
      this.step = 3
    },
    goToStep3(wallet, paypal, skip)
    {
      if(!skip)
      {
        if (wallet) {
        this.step_3.wallet = wallet
        }
        if (paypal) {
          this.step_3.paypal = paypal
        }
        this.step = 4
        this.submit()
        window.scrollTo({top: 0, behavior: 'smooth'})
      }else{
        this.step = 4
        this.step_3.return = false
      }
      
    },
    getProducts(){
        let id = this.$route.params.id;
        this.axios
          .get('/products/' + id)
          .then(({ data }) => {
            this.arrays.product = data.products
            this.display.payment = data.products.monthly_price
            this.display.type = 'monthly',
            this.display.text = 'Month'
            if(data.products.subs_plan && data.products.subs_plan.length > 0)
            {
              this.status.subs_plan = true
              this.arrays.plans = data.products.subs_plan
            }
            this.checkInviter()
          })
    },
    getUserInfo() {
      
      this.arrays.me = this.myInfo
      if(this.myInfo.default_user_company.role == 'SA')
      {
        this.status.admin = true
      }
      this.getProducts();
      this.getWallet();
      
    },
    getWallet() {
      this.axios
        .get("accounting?wallet=true&wallet_balance=true&per_page=1&user_id=" + this.arrays.me.id)
        .then(({ data }) => {
          if(data.accounting[0])
          {
            let balance = this.checkWalletBalance(data.accounting[0])
            if(balance > 0)
            {
              this.arrays.wallet = data.accounting[0]
              this.arrays.wallet['balance'] = balance
            }
          }
        })
        .catch()
      },
    checkWalletBalance(wallet)
    {
      let debit = 0
      let credit = 0
      if(wallet.credit_total)
      {
        credit = wallet.credit_total
      }

      if(wallet.debit_total)
      {
        debit = wallet.debit_total
      }
      return debit + credit
    },
    checkInviter()
    { 
      this.axios.get('inviter?user_id='+this.arrays.me.id)
      .then( ({data}) => {
        if(data.inviter && data.inviter.length > 0)
        {
          this.arrays.inviter = data.inviter[0]
          this.status.referral = true
        }
      })      
    },
    calculateReferral()
    {
      if(this.arrays.inviter && this.arrays.inviter.referror)
      {
        let referral_status = this.$options.filters.checkInviterExpirationDate(this.arrays.inviter.date_time_created)
        if(!referral_status)
          {
            let product_refferal_type = this.arrays.product.referral_type
            let product_referral_amount = this.arrays.product.referral_amount
            let total = 0
            if(product_refferal_type == 0)
            {
              let percentage = product_referral_amount / 100
              total = this.step_2.payment * percentage
            }
            if(product_refferal_type == 1)
            {
              total = product_referral_amount
            }
          
            this.calculation.inviter = {
              user_id : this.arrays.inviter.referror.id,
              amount : this.$options.filters.amount( total ),
              invited_company : this.arrays.inviter.company.name
            }
           
          }
      }
    },
    arrangeUser() 
    {
      let arrange = []
      let subscription_type = this.step_2.subscription_type
      let company_id = this.arrays.me.default_user_company.company.id
      
      if (this.arrays.product.type == 'per user') {
        
 
        for( let u in this.step_2.selected)
        {
          if (!isNaN(u)) {
            if(this.step_2.selected[u].calculate)
            {
              arrange[u] = {
                products_id : this.arrays.product.id,
                start_of_subscription : this.$options.filters.apiDate( this.step_2.selected[u].calculate[subscription_type].start ) ,
                end_of_subscription : this.$options.filters.apiDate(  this.step_2.selected[u].calculate[subscription_type].end ),
                user_company_id : this.step_2.selected[u].user_company_id,
                subscription_amount : parseFloat( this.$options.filters.amount(  this.step_2.selected[u].calculate[subscription_type].amount) ),
                transaction_id : null,
                status : 'paid',
                expired : this.step_2.selected[u].expired ? true : false,
                include : this.step_2.selected[u].include ? this.step_2.selected[u].include : false
              }
            }
          }
        }

        if(arrange.length > 0)
          {
            for(let i in arrange)
            {
              if(arrange[i].user_company_id == this.arrays.me.current_user_company_id)
              {
      
                if(arrange[i].include)
                {
                  if(!arrange[i].include[this.step_2.subscription_type])
                    arrange.splice(i, 1)
                  }
              }
            }
          }
        return arrange
      }else{
        let data = [ 
          {
            products_id : this.arrays.product.id,
            start_of_subscription : this.$options.filters.apiDate(  this.step_2.my_subscription.start ),
            end_of_subscription : this.$options.filters.apiDate( this.step_2.my_subscription[this.step_2.subscription_type] ),
            user_company_id : company_id,
            subscription_amount : parseFloat( this.$options.filters.amount(  this.step_2.payment) ),
            transaction_id : null,
            status : 'paid'
          }
        ]
        return data
      }
    },
    arrangeUnpaid()
    {
      let arrange = []
      if(this.hasUnpaid)
      {
        for(let u in this.hasUnpaid)
        {
          if( !isNaN(u) ){
            arrange[u] = {
              id : this.hasUnpaid[u].id
            }
          }
        }
      }
      return arrange
    },
    checkPaymentMethod(method)
    {
      let m = null

      for(let i in this.arrays.payment_method)
      {
        if (this.arrays.payment_method[i].name == method) {
          m = this.arrays.payment_method[i].id
        }
      }
      if(m)
      {
        return m
      }else{
        return 1
      }
    },
    submit() {
         this.step_3.return = false
    },
    updateCompanyBilling()
    {
      let company = this.arrays.me.default_user_company.company
      if(company)
      {
        company.billing_date = this.status.new_company_date
      }
      this.axios.put('companies/' + company.id, company)
      .finally( () => {
         this.step_3.return = false
      })
    },
    stepBack()
    {
      this.step--
      window.scrollTo({top: 0, behavior: 'smooth'})
      
    },
    formatNumber(n) {
      return Number(n).toLocaleString();
    },
  }
};
</script>


<style scoped>
html {
  scroll-behavior: smooth;
}
.mr-md-4 {
  text-transform: none;
  font-size: small;
}
.scrollablesmall {
  overflow-x: auto;
  cursor: pointer;
}
.scrollablesmall::-webkit-scrollbar {
  width: 0;
}
button:active,
button:focus {
  outline: none;
}
.v-stepper{
  box-shadow: none;
}
</style> 

